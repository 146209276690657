import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import SignUpPage from "./pages/auth/SignUpPage";
import MainAuth from "./components/layout/MainAuth";
import SignInPage from "./pages/auth/SignInPage";
import AuthProvider from "./context/auth/AuthProvider";
import RequireAuth from "./middleware/RequireAuth";
import ManageRoute from "./middleware/ManageRoute";
import Main from "./components/layout/Main";
import SignOutPage from "./pages/auth/SignOutPage";
import ServerError from "./pages/error/500";
import LayoutProvider from "./context/layout/LayoutProvider";
import RequireGuest from "./middleware/RequireGuest";
import ForgetPasswordPage from "./pages/auth/ForgetPasswordPage";
import VerifyCodePage from "./pages/auth/VerifyCodePage";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";
import EmailVerificationPage from "./pages/auth/EmailVerificationPage";
import SignInIntoApp from "./pages/auth/SignInIntoAppPage";
import AppPage from "./pages/AppPage";
import RequireRole from "./middleware/RequireRole";
import RolePage from "./pages/RolePage";
import LanguagePage from "./pages/LanguagePage";
import TypeSMSPage from "./pages/TypeSMSPage";
import ModelSMSPage from "./pages/ModelSMSPage";
import Unautorized from "./pages/error/403";
import UserPage from "./pages/UserPage";
import { UserRoles } from "./config/global";
import HomePage from "./pages/home/HomePage";
import SingleOrganizationPage from "./pages/organization/SingleOrganizationPage";
import SingleOrganizationInformationPage from "./pages/organization/SingleOrganizationInformationPage";
import OrganizationPage from "./pages/organization/OrganizationPage";
import SingleOrganizationAppPage from "./pages/organization/SingleOrganizationAppPage";
import SingleOrganizationUserPage from "./pages/organization/SingleOrganizationUserPage";
import ConfirmationEmailPage from "./pages/auth/ConfirmationEmailPage";

function App() {
    const queryClient = new QueryClient()
    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <LayoutProvider>
                    <Routes>
                        <Route element={<RequireGuest />}>
                            <Route element={<MainAuth />}>
                                <Route path={"sign-up"} element={<SignUpPage />} />
                                <Route path={"sign-in"} element={<SignInPage />} />
                                <Route path={"forget-password"} element={<ForgetPasswordPage />} />
                                <Route path={"verify-code"} element={<VerifyCodePage />} />
                                <Route path={"reset-password"} element={<ResetPasswordPage />} />
                                <Route path={"auth/reset-password/:code"} element={<EmailVerificationPage />} />
                                <Route path={"auth/confirm-email/:code"} element={<ConfirmationEmailPage />} />
                            </Route>
                        </Route>
                        <Route element={<RequireAuth />}>
                            <Route element={<Main />}>
                                <Route path={"home"} element={<HomePage />} />
                                <Route path={"application"} element={<AppPage />} />
                                <Route path={"user"} element={<UserPage />} />
                                <Route element={<RequireRole roles={[UserRoles.ADMIN]} />}>
                                    <Route path={"role"} element={<RolePage />} />
                                    <Route path={"language"} element={<LanguagePage/>} />
                                    <Route path={"type-sms"} element={<TypeSMSPage/>} />
                                    <Route path={"model-sms"} element={<ModelSMSPage/>} />
                                    <Route path={"organization"} element={<OrganizationPage />} />
                                    <Route path={"organization/:id"} element={<SingleOrganizationPage />}>
                                        <Route index element={<SingleOrganizationInformationPage />} />
                                        <Route path={"application"} element={<SingleOrganizationAppPage />} />
                                        <Route path={"user"} element={<SingleOrganizationUserPage />} />
                                    </Route>
                                </Route>
                            </Route>
                            <Route path={"connect/:appSlug"} element={<SignInIntoApp />} />
                        </Route>
                        <Route path="logout" element={<SignOutPage />} />
                        <Route path="500" element={<ServerError />} />
                        <Route path="403" element={<Unautorized />} />
                        <Route path="*" element={<ManageRoute />} />
                    </Routes>
                </LayoutProvider>
            </AuthProvider>
        </QueryClientProvider>
    );
}

export default App;
