import Card from "../../components/card/Card";
import { Form } from "../../components/form/Form";
import { useForm } from "react-hook-form";
import Row from "../../components/grid/Row";
import Col from "../../components/grid/Col";
import { InputEmail, InputText, InputUrl } from "../../components/form/Input";
import { SubmitButton } from "../../components/button/CustomButton";
import { useParams } from "react-router-dom";
import { useQueries } from "../../hooks/useQueries";
import { APP_API_URL } from "../../config/api";

export default function UserInfosPage() {
  /**
   * Hooks
   */
  const params = useParams();

  /**
   * Form
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */
  const { data: organization, isLoading } = useQueries(
    APP_API_URL + `organization/${params.id}`,
    ["organization-detail", params.id]
  );

  /**
   * Functions
   */
  const handleSubmitForm = () => {};

  return (
    <Card title="Information de l'entreprise">
      <Form loading={false} onSubmit={handleSubmit(handleSubmitForm)}>
        <Row cols={2} gap={4}>
          <Col>
            <InputText
              showSkeleton={isLoading}
              name="name"
              label="Nom"
              placeholder="Entrez vos noms et prénoms"
              register={register}
              error={errors?.name}
              defaultValue={organization?.data.data.name}
            />
          </Col>
          <Col>
            <InputEmail
              showSkeleton={isLoading}
              name="email"
              label="Adresse email"
              placeholder="Entrez l'adresse email"
              register={register}
              error={errors?.email}
              defaultValue={organization?.data.data.email}
            />
          </Col>
          <Col>
            <InputText
              showSkeleton={isLoading}
              name="phone"
              label="Numéro de téléphone"
              placeholder="Entrez le numéro de téléphone"
              register={register}
              error={errors?.phone}
              defaultValue={organization?.data.data.phone}
            />
          </Col>
          <Col>
            <InputText
              showSkeleton={isLoading}
              placeholder={"Entrer la ville"}
              label={"Ville"}
              register={register}
              error={errors?.town}
              name={"town"}
              defaultValue={organization?.data.data.town}
            />
          </Col>
          <Col>
            <InputText
              showSkeleton={isLoading}
              placeholder={"Entrer l'adresse"}
              label={"Adresse"}
              register={register}
              error={errors?.address}
              name={"address"}
              defaultValue={organization?.data.data.address}
            />
          </Col>
          <Col>
            <InputUrl
              showSkeleton={isLoading}
              name="webSite"
              label="Site web"
              placeholder="Entrez lien du site web"
              register={register}
              error={errors?.webSite}
              defaultValue={organization?.data.data.web_site}
            />
          </Col>
        </Row>
        <hr className="my-4" />
        <div className="mt-4 w-full">
          <SubmitButton
            className="ml-auto"
            value={"Mettre à jour"}
            loading={false}
          />
        </div>
      </Form>
    </Card>
  );
}
