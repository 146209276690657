import { useForm } from "react-hook-form";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import { useEffect } from "react";
import { FormActionType, HTTPStatusResponse } from "../config/global";
import { toast } from "react-toastify";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import { InputText } from "../components/form/Input";
import { Select2 } from "../components/form/Select";
import { BadgeRounded } from "../components/badge/Badge";
import ModalForm from "../components/modal/ModalForm";
import { AddButton, EditButton } from "../components/button/CustomButton";
import { ColumnInterface } from "../components/table/typings";
import CardTable from "../components/card/CardTable";
import SimpleButton from "../components/button/SimpleButton";
import { BiKey } from "react-icons/bi";

export default function AppPage() {
  /**
   * Hooks
   */
  const {
    baseApiUrl,
    title,
    queryKey,
    actionForm,
    setActionForm,
    titleForm,
    setTitleForm,
    currentElement,
    setCurrentElement,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
  } = useHeaderFunctionPage({
    baseApiUrl: "application",
    title: "Applications",
  });

  /**
   * Form
   */
  const {
    register,
    setValue,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?pageNo=${pagination.page}&pageSize=${pagination.perPage}`,
    queryKey
  );

  const { data: roles, refetch: refetchRole } = useQueries(
    APP_API_URL + `role-list`,
    ["role-list"],
    {
      enabled: false,
    }
  );

  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-store`,
      "post",
      queryKey
    );

  const { mutateAsync: updateData, isLoading: updateDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}/${currentElement}`,
      "put",
      queryKey
    );

  /**
   * Functions
   */
  const handleSubmitAddForm = (values: any) => {
    const roles = values.roles.map((role: any) => role.id);
    storeData({ ...values, roles: roles }).then((response: any) => {
      if (response?.data?.status === HTTPStatusResponse.OK) {
        reset();
        setOpenModal(false);
        toast.success("Application ajoutée");
      }
    });
  };

  const handleSubmitEditForm = (values: any) => {
    const roles = values.roles.map((role: any) => role.id);
    updateData({ ...values, roles: roles }).then((response: any) => {
      if (response?.data?.status === HTTPStatusResponse.OK) {
        reset();
        setOpenModal(false);
        setCurrentElement(0);
        toast.success("Application modifiée");
      }
    });
  };

  const handleAddElement = () => {
    setTitleForm("Créer une application");
    setActionForm(FormActionType.ADD);
    setCurrentElement(0);
    setOpenModal(true);
    reset();
  };

  const handleEditElement = (element: any) => {
    setTitleForm("Modifier l'application");
    setActionForm(FormActionType.EDIT);
    setValue("name", element.name);
    setValue("slug", element.slug);
    setValue("description", element.description);
    setValue("appWebUrl", element.appWebUrl);
    setValue("appApiUrl", element.appApiUrl);
    setValue("logoUrl", element.logoUrl);
    setValue("roles", element.roles);
    setCurrentElement(element.slug);
    setOpenModal(true);
  };

  const handleCancelForm = () => {
    reset();
    setCurrentElement(0);
    setOpenModal(false);
  };

  const handleCopySecretToClip = (secret: string) => {
    navigator.clipboard.writeText(secret);
    toast.info("La clé de l'application a été copiée dans le presse papier", {
      progressClassName: "bg-primary",
    });
  };

  /**
   * Effect
   */
  useEffect(() => {
    refetchRole();
  }, []);

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Slug",
      key: "slug",
      dataIndex: "slug",
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
    },
    {
      title: "Web URL",
      key: "appWebUrl",
      dataIndex: "appWebUrl",
    },
    {
      title: "API Url",
      key: "appApiUrl",
      dataIndex: "appApiUrl",
    },
    {
      title: "Secret",
      key: "secret",
      render: (element: any) => (
        <SimpleButton onClick={() => handleCopySecretToClip(element.secret)}>
          <BiKey />
        </SimpleButton>
      ),
    },
    {
      title: "Roles",
      key: "roles",
      render: (element: any) =>
        element.roles.map((role: any) => (
          <BadgeRounded text={role.name} key={role.name} className="mr-1 bg-secondary" />
        )),
    },
    {
      title: "Image",
      key: "logoUrl",
      render: (element: any) => (
        <img
          src={element.logoUrl}
          className="h-16 w-16 object-cover mx-auto"
          alt={element.name}
        />
      ),
    },
    {
      title: <span style={{ textAlign: "center" }}>#</span>,
      dataIndex: "actions",
      key: "actions",
      render: (element: any) => (
        <div className="flex justify-center gap-2">
          <EditButton onClick={() => handleEditElement(element)} />
        </div>
      ),
    },
  ];

  //UI
  return (
    <>
      <CardTable
        extras={[<AddButton key={"add"} onClick={handleAddElement} />]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
      <ModalForm
        title={titleForm}
        isLoading={
          actionForm === FormActionType.ADD
            ? storeDataIsLoading
            : updateDataIsLoading
        }
        onClose={handleCancelForm}
        onConfirm={handleSubmit(
          actionForm === FormActionType.ADD
            ? handleSubmitAddForm
            : handleSubmitEditForm
        )}
        open={openModal}
      >
        <div className={"grid grid-cols-1 gap-4 sm:grid-cols-2"}>
          <div>
            <InputText
              placeholder={"Entrer le nom"}
              label={"Nom"}
              register={register}
              error={errors?.name}
              name={"name"}
            />
          </div>
          <div>
            <InputText
              placeholder={"Entrer le slug"}
              label={"Slug"}
              register={register}
              error={errors?.slug}
              name={"slug"}
            />
          </div>
          <div className="col-span-2">
            <InputText
              placeholder={"Entrer la description"}
              label={"Description"}
              register={register}
              error={errors?.description}
              name={"description"}
            />
          </div>
          <div className="col-span-2">
            <InputText
              placeholder={"Entrer le lien web"}
              label={"Lien Web"}
              register={register}
              error={errors?.appWebUrl}
              name={"appWebUrl"}
            />
          </div>
          <div className="col-span-2">
            <InputText
              placeholder={"Entrer le lien Api"}
              label={"Lien API"}
              register={register}
              error={errors?.appApiUrl}
              name={"appApiUrl"}
            />
          </div>
          <div className="col-span-2">
            <InputText
              placeholder={"Entrer le lien de l'image"}
              label={"Image"}
              register={register}
              error={errors?.logoUrl}
              name={"logoUrl"}
            />
          </div>
          <div className="col-span-2">
            <Select2
              multiple
              items={roles?.data.data}
              control={control}
              placeholder={"Sélectionner le role"}
              label={"Role"}
              register={register}
              error={errors?.roles}
              name={"roles"}
              textKeyItem={"name"}
              valueKeyItem={"id"}
            />
          </div>
        </div>
      </ModalForm>
    </>
  );
}
